.job_details-container,
.job_details-schedule_container {
	font-size: 20px;
	border: 1px solid rgba(183, 182, 212, 0.207);
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 8%;
	margin-right: 8%;
	border-radius: 5px;
	margin-top: 5%;
	display: inline;
	justify-content: space-evenly;
	flex-wrap: wrap;
	background-color: rgba(183, 182, 212, 0.207);
	box-shadow: 0px 0px 10px #d3eef9;
}

.job_details-outer_container {
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	gap: 1vh;
}

.job_details-details {
	max-width: 20vw;
}

.job_details-title {
	font-size: 25px;
	text-decoration: underline;
}

.customer_job_details-disable_buttons {
	text-align: center;
	justify-content: space-evenly;
}
