.assign_job_modal-container {
	display: flex;
	flex-direction: column;
}

.assign_job_modal-job,
.assign_job_modal-description,
.assign_job_modal-price,
.assign_job_modal-category {
	padding: 5px;
	font-size: 20px;
}

.assign_job_modal-title {
	padding-bottom: 10px;
}

.assign_job_modal-select {
	margin: 5px;
	padding: 2px;
	font-size: 18px;
}

.accept_job-assign_container {
	text-align: end;
	margin: 5px;
	margin-right: 20px;
}

.accept_job-assign_button {
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #a0cea89b;
	padding: 10px;
	background-color: #d3f9df;
	border-radius: 7px;
	width: 20%;
	margin: 8px;
}

.accept_job-assign_button:hover {
	cursor: pointer;
	background-color: #45cc647a;
	box-shadow: 0px 0px 8px #a4d9a7;
}
