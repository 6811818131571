/* TODO Add site wide styles */
* {
	margin: 0;
	padding: 0;
	font-family: "Varela Round", sans-serif;
	font-family: "Wix Madefor Display", sans-serif;
}

body {
	margin: 0 auto;
}

html {
	background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0.9),
			rgba(255, 255, 255, 0.9)
		),
		url("https://i.imgur.com/6H6dkkv.png");
	background-attachment: absolute;
	background-size: cover;
}

.taskText {
	font-size: 16px;
}

input {
    font-size: 20px;
}
