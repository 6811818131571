.hidden-landing {
	opacity: 0;
	filter: blur(5px);
	transform: translateX(-100%);
	transition: all 4s;
}
.technician-images {
	display: flex;
	gap: 1vw;
	margin-top: 5%;
}

.show-landing {
	opacity: 1;
	filter: blur(0);
	transform: translateX(0);
}

.technician-image {
	border-radius: 40px;
}

.technician-image:nth-child(4) {
	transition-delay: 400ms;
}
.technician-image:nth-child(3) {
	transition-delay: 900ms;
}
.technician-image:nth-child(2) {
	transition-delay: 1300ms;
}
.technician-image:nth-child(1) {
	transition-delay: 1500ms;
}

.category-images {
	margin-top: 8vh;
	display: flex;
	gap: 10px;
}
.landing-demo_buttons_container {
	margin-top: 10vh;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	gap: 15%;
	width: 80%;
}

.landing_button {
	border-radius: 10px;
	text-align: center;
	box-shadow: 0px 0px 20px #8ec5db;
	list-style: none;
	border: solid white 2px;
	font-size: 30px;
	padding: 20px;
	width: 100%;
	position: relative;
	transition: all 1s;
	max-width: 30%;
}
.landing_button:hover {
	cursor: pointer;
	scale: 1.2;
}

.manager-landing_button {
	border: solid rgb(252, 198, 198) 2px;
	box-shadow: 0px 0px 20px #ff0000;
}

.technician-landing_button {
	border: solid rgb(200, 198, 252) 2px;
	box-shadow: 0px 0px 20px #0400ff;
}

.customer-landing_button {
	border: solid rgb(198, 252, 212) 2px;
	box-shadow: 0px 0px 20px #00ff2f;
}

.landing-session {
	font-size: 30px;
	color: #d3eef9;
}

.category-image {
	background-color: #dddddd;
	border-radius: 10px;
}

.landing-image {
	mix-blend-mode: multiply;
	border-radius: 10px;
}

.category-image:nth-child(1) {
	transition-delay: 1800ms;
}
.category-image:nth-child(2) {
	transition-delay: 1300ms;
}
.category-image:nth-child(3) {
	transition-delay: 800ms;
}
.category-image:nth-child(4) {
	transition-delay: 200ms;
}

section {
	margin-left: 10%;
	margin-right: 10%;
	display: grid;
	place-items: center;
	align-content: center;
	min-height: 110vh;
}
.home-logo {
	--bg-size: 400%;
	text-align: center;
	padding-top: 2%;
	font-size: 45px;
	--color-one: #d3eef9;
	--color-two: white;
	background: linear-gradient(
			90deg,
			var(--color-one),
			var(--color-two),
			var(--color-one)
		)
		0 0 / var(--bg-size) 100%;
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	animation: move-bg 8s infinite linear;
	box-shadow: 0px 0px 5px #a4cad9;
}

@keyframes move-bg {
	0% {
		background-position: var(--bg-size) 0%;
		box-shadow: 0px 0px 10px #a4cad9;
	}
	50% {
		box-shadow: 0px 0px 20px #a4cad9;
	}
	100% {
		box-shadow: 0px 0px 10px #a4cad9;
	}
}

.home-paragraph {
	font-size: 35px;
}

.home-secondary {
	font-size: 40px;
	padding-bottom: 3vh;
}

.landing_page-container {
	background-color: #121212;
	color: #d3eef9;
}
