.jobs_done-title,
.jobs_title,
.techs_title {
	margin: 0 auto;
	display: flex;
	padding-left: 5%;
	padding-top: 20px;
	padding-bottom: 20px;
}

.manager_schedule {
	margin: 0 auto;
	text-align: center;
	padding-top: 3vh;
}

.no_techs-title {
	margin: 0 auto;
	text-align: center;
	padding-top: 3vh;
}

.jobs_done,
.jobs,
.techs {
	border: 1px solid rgba(183, 182, 212, 0.207);
	padding-top: 20px;
	padding-bottom: 20px;
	margin-left: 8%;
	margin-right: 8%;
	border-radius: 5px;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	background-color: rgba(183, 182, 212, 0.207);
	box-shadow: 0px 0px 10px #d3eef9;
	min-height: 50px;
}

.jobs_container,
.techs_container {
	display: flex;
	flex-wrap: wrap;
}

.new_tech-button {
	box-shadow: 0px 0px 8px #a4d9a7;
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #a0cea89b;
	padding: 10px;
	background-color: #d3f9df;
	border-radius: 7px;
}

.new_tech-button:hover {
	cursor: pointer;
	background-color: #45cc607a;
}

.new_techs_container {
	margin: 0 auto;
	display: flex;
	justify-content: end;
	padding-right: 8%;
	padding-top: 30px;
	padding-bottom: 20px;
}

.assign_tech_button,
.techs_button {
	min-width: none;
	max-width: 320px;
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #a0c1ce9b;
	padding: 10%;
	margin: 10px;
	background-color: #d3eef9;
	box-shadow: 0px 0px 20px #a4cad9;
	border-radius: 7px;
	min-height: 120px;
	min-width: 200px;
}

.done_job {
	min-width: none;
	max-width: 320px;
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #cea0a09b;
	padding: 10%;
	margin: 10px;
	background-color: #ffafaf;
	box-shadow: 0px 0px 20px #d9a4a4;
	border-radius: 7px;
	min-height: 120px;
	min-width: 200px;
}

.done_job:hover {
	cursor: pointer;
	background-color: #cc45457a;
}

.assign_tech_button:hover,
.techs_button:hover {
	cursor: pointer;
	background-color: #45a6cc7a;
}
