.job_schedules-container {
	font-size: 20px;
	border: 1px solid rgba(183, 182, 212, 0.207);
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 8%;
	margin-right: 8%;
	border-radius: 5px;
	margin-top: 5%;
	display: inline;
	justify-content: space-evenly;
	flex-wrap: wrap;
	background-color: rgba(183, 182, 212, 0.207);
	box-shadow: 0px 0px 10px #d3eef9;
}

.job_details-schedule_container-tech {
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	gap: 1vh;
}
.job_details-container-tech {
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	gap: 1vh;
}

.tech_solution_textarea {
	width: 100%;
	max-width: 100%;
	font-size: 19px;
	resize: vertical;
}

.job_details-start_button {
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #a0cea29b;
	padding: 10px;
	background-color: #afffb8;
	border-radius: 7px;
	width: 40%;
	margin: 8px;
}

.job_details-start_button:hover {
	cursor: pointer;
	background-color: #45cc647a;
	box-shadow: 0px 0px 8px #a4d9a7;
}

.job_details-cancel_button {
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #cea0a09b;
	padding: 10px;
	background-color: #ffafaf;
	border-radius: 7px;
	width: 40%;
	margin: 8px;
}

.job_details-cancel_button:hover {
	cursor: pointer;
	background-color: #cc45457a;
}

.job_details-end_button {
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #a1a0ce9b;
	padding: 10px;
	background-color: #b7afff;
	border-radius: 7px;
	width: 40%;
	margin: 8px;
}

.job_details-end_button:hover {
	cursor: pointer;
	background-color: #5a5cd8;
	box-shadow: 0px 0px 10px #877ddee3;
	color: white;
}

.job_details-end_button:disabled {
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #8c8c8d9b;
	padding: 10px;
	background-color: #c8c8c8;
	border-radius: 7px;
	width: 40%;
	margin: 8px;
}

.job_details-end_button:disabled:hover {
	cursor: not-allowed;
	background-color: #9090937a;
	box-shadow: 0px 0px 10px #000000;
	color: gray;
}

.job_details-start_button:disabled {
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #8c8c8d9b;
	padding: 10px;
	background-color: #c8c8c8;
	border-radius: 7px;
	width: 40%;
	margin: 8px;
}

.job_details-start_button:disabled:hover {
	cursor: not-allowed;
	background-color: #9090937a;
	box-shadow: 0px 0px 10px #000000;
}

.job_details-cancel_button:disabled {
	border: none;
	background-color: transparent;
	padding: 5px;
	font-size: 17px;
	border: 2px solid #8c8c8d9b;
	padding: 10px;
	background-color: #c8c8c8;
	border-radius: 7px;
	width: 40%;
	margin: 8px;
}

.job_details-cancel_button:disabled:hover {
	cursor: not-allowed;
	background-color: #9090937a;
	box-shadow: 0px 0px 10px #000000;
}
