.tech_homepage-job_container {
	margin-top: 8vh;
	align-content: center;
	justify-content: center;
}

.tech_homepage-job_header {
	margin-left: 4vw;
	margin-bottom: 4vh;
}

.tech_homepage-jobs {
	border: 1px solid rgba(183, 182, 212, 0.207);
	padding-top: 20px;
	padding-bottom: 20px;
	margin-left: 8%;
	margin-right: 8%;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
	background-color: rgba(183, 182, 212, 0.207);
	box-shadow: 0px 0px 10px #d3eef9;
	gap: 1vw;
	margin-top: 2.5%;
	margin-bottom: 2.5%;
	min-height: 100px;
}

.job__title {
	text-align: center;
	font-size: 20px;
	padding-bottom: 15px;
	padding-left: 15px;
	padding-right: 15px;
	font-weight: bolder;
	max-width: 240px;
}

.tech_a_job {
	border: solid transparent 2px;
}
.tech_a_job:hover {
	border: solid rgb(70, 70, 70) 2px;
}

.job_image {
	mix-blend-mode: multiply;
}

.General {
	box-shadow: 0px 0px 20px #5b75e9;
	border-radius: 10px;
}

.Moving {
	box-shadow: 0px 0px 20px #000000;
	border-radius: 10px;
}

.Plumbing {
	box-shadow: 0px 0px 20px #191cddd2;
	border-radius: 10px;
}

.Electrical {
	box-shadow: 0px 0px 20px #f2d427;
	border-radius: 10px;
}

.Network {
	box-shadow: 0px 0px 20px #5be970;
	border-radius: 10px;
}

.Construction {
	box-shadow: 0px 0px 20px #ffb300de;
	border-radius: 10px;
}

.Demolition {
	box-shadow: 0px 0px 20px #e9705b;
	border-radius: 10px;
}

.Automotive {
	box-shadow: 0px 0px 20px #f64040;
	border-radius: 10px;
}

.NurseCall {
	box-shadow: 0px 0px 20px #f1ad3f;
	border-radius: 10px;
}
